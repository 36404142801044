import {BaseService} from "@/core/services/BaseService";

class PermissionService extends BaseService<any> {
  endpoint = "/permission";

  /**
   * load user permission app
   */
  public async getList() {
    return this.http().get(this.endpoint + "/build").then(res => {
      return res.data
    })
  }

  async getTeamPermission(teamId) {
    return this.http().get(this.endpoint + "/team/" + teamId).then(res => {
      return res.data
    })
  }

  async getUserPermission(userId) {
    return this.http().get(this.endpoint + "/user/" + userId).then(res => {
      return res.data
    })
  }
  public async addTeamPermission(teamId: any, func: any) {
    return this.http().post(this.endpoint + "/team/" + teamId + "/" + func).then(res => {
      return res.data
    })
  }
  public async deleteTeamPermission(teamId: any, func: any) {
    return this.http().delete(this.endpoint + "/team/" + teamId + "/" + func).then(res => {
      return res.data
    })
  }

  public async addUserPermission(userId: any, func: any) {
    return this.http().post(this.endpoint + "/user/" + userId + "/" + func).then(res => {
      return res.data
    })
  }

  public async deleteUserPermission(teamId: any, func: any) {
    return this.http().delete(this.endpoint + "/user/" + teamId + "/" + func).then(res => {
      return res.data
    })
  }
}

export default new PermissionService();

import {defineComponent, ref} from "vue";
import PermissionService from "@/core/services/PermissionService";
import {userRoleCheck} from "@/core/composite/composite";


export default defineComponent({
  name: "Permissions.vue",
  props: {
    teamId: {
      required: true
    }
  },
  setup(props) {

    const loading = ref(false);
    const list = ref<any>()
    const functions = ref<any>([])
    const getList = () => {
      PermissionService.getList().then(res => {
        list.value = res;
      })
    }
    const getData = () => {
      PermissionService.getTeamPermission(props.teamId).then(res => {
        functions.value = res;
      })
    }
    getList();
    getData();
    return {
      loading,
      list,
      functions,
      ...userRoleCheck(),
    }
  },
  methods: {
    onChange($event) {
      this.loading = true;
      if ($event.target.checked) {
        PermissionService.addTeamPermission(this.teamId, $event.target.value).finally(() => {
          this.loading = false
        })
      } else {
        PermissionService.deleteTeamPermission(this.teamId, $event.target.value).finally(() => {
          this.loading = false
        })
      }
    }
  }
})
